<template>
  <Ethereum :callback="initialize" />
  <div class="main">
    <div v-if="started == false && ownedSubstrates.length > 0">
      <h2>SELECT YOUR SHIP</h2>
      <div 
        v-for="(collection, index) in ownedSubstrates" 
        :key="index"
        class="saleGrid"
      >
        <div v-if="collection.length == 0" class="center">
          <h3>Please ser, you need to have a ship before you can play.  </h3>
          <h3>Please visit the  
            <router-link class="link" v-smooth-scroll to="/mint">
              shop
            </router-link>
          </h3>
        </div>
        <div 
          v-for="(item, index2) in collection" 
          :key="index2"
          class="saleItem"
          v-bind:class="[possibleShips[index2] ? 'selected' : '']"
          @click="selectShip(index2)"
        >
          <img :src="item.metadata.image" class="thumb" />
        </div>
      </div>
    </div>
    <div v-if="itemSelected > -1" class="center">
      <div>PRESS START </div><br>
      <Button @click="startGame">START</Button>
    </div>
    <div v-show="started">
      <canvas class="screen" ref="canvas1"></canvas>
    </div>
  </div>
  
</template>
<script>
import Button from '/src/components/ui/Button.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import start from '/src/game/useGame';

import { computed, ref, onMounted, onUnmounted, created, watch } from 'vue';
import { useStore } from 'vuex';


export default {
  components: {
      Button,
      Ethereum
  },

  created() {
    
  },

  setup() {
    const store = useStore();
    const loggedInAddress = computed(() => {
      return store.state.ethers.address;
    });

    const started = ref(false);

    const canvas1 = ref(null);
    const windowWidth = ref(window.innerWidth);
    const windowHeight = ref(window.innerHeight);
    let ctx = null;
    let canvasWidth = 0;
    let canvasHeight = 0;

    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
        windowHeight.value = window.innerHeight;
      });
    });

    onUnmounted(() => {
      window.removeEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
        windowHeight.value = window.innerHeight;
      });
    });

    const startGame = async () => {
      started.value = true;
      ctx = canvas1.value.getContext('2d');
      canvasWidth = canvas1.value.width = windowWidth.value;
      canvasHeight = canvas1.value.height = windowHeight.value;

      // glhf
      //   console.log("playerImage.value", playerImage.value);
      start(ctx, canvasWidth, canvasHeight, playerImage.value, playerAttributes.value);
    }

    const initialize = async () => {
      await store.dispatch(
        'composite/getOwnedSubstrates',
        { },
        { root: true }
      );
    };

    const ownedSubstrates = computed(() => {
      return store.state.composite.ownedSubstrates;
    });

    const possibleShips = computed(() => {
    //   console.log("ownedSubstrates", store.state.composite.ownedSubstrates);
      let ships = [];
      if(store.state.composite.ownedSubstrates.length > 0){
        for(let i = 0; i < ownedSubstrates.value[0].length; i++){
          if(itemSelected.value === i ){
            ships[i] = true;
          }else{
            ships[i] = false;
          }
        }
      }

      return ships;
    });

    const itemSelected = ref(-1);

    const playerImage = ref(null);
    const playerAttributes = ref(null);

    const selectShip = async (index) => {
      let selectedShip = store.state.composite.ownedSubstrates[0][index];
      //console.log("layerMetadata", selectedShip.layerMetadata);
      //playerImage.value = selectedShip.metadata.image;
      playerImage.value = selectedShip.svgdata;
      playerAttributes.value = {
        Drone: [],
        Engine: [],
        Weapon: [],
        Cosmetic: []
      };
      for(let layer of selectedShip.layerMetadata){
        for(let attr of layer.attributes){
          if(attr.trait_type == 'Type'){
            playerAttributes.value[attr.value].push(layer.attributes)
          }
        }
      }
    //   console.log("sorted attributes", playerAttributes.value);
      itemSelected.value = index;
    };

    return {
      canvas1,
      initialize,
      itemSelected,
      ownedSubstrates,
      possibleShips,
      selectShip,
      started,
      startGame,
      store,
      loggedInAddress,
      windowWidth,
      windowHeight,
    };
  }
};
</script>
<style scoped lang="scss">
h2 {
  margin:30px 0;
  text-align:center;
}
.home {
  text-align: center;
  padding: 50px;
}
.main {
  overflow-y: hidden;
  width:80%;
  margin:0 auto;
}
.thumb {
  width:160px;
  height:160px;
}
.saleItem {
  width:200px;
  margin:10px;
  padding:20px;
}
.saleItem:hover {
  border:3px solid green;
}

.saleGrid {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
}
.screen {
  position:fixed;
  font-family: "DeterminationMono", sans-serif;
  top:0;
  left:0;
  background: rgb(5, 2, 20);
  z-index:100;
  cursor:none;
}

.appContainer { 
  min-height:0;
}

.center {
  width: 100%;
  text-align:center;
}

.selected {
  border:3px solid green;
  background:green;
}
</style>
