'use strict';

// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = {
  substrateItem: {},
  mintableItems: [],
  ownedItems: {},
  ownedSubstrates: {},
  equippableItems: {},
};

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
