'use strict';

// Imports.
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';
import config from '/src/config'
import { log } from '/src/utility';

// A helper function to parse pool output data.
const getUserLayers = async function(contract, id) {
    let provider = await ethersService.getProvider();
    let signer = await provider.getSigner();
    let itemContract = new ethers.Contract(
        contract,
        config.composite721ABI,
        provider
    );
    let layerCount = await itemContract.layerCounts(id);
    let layerData = [];
    for(let i = 0; i < layerCount; i++){
        let layer = await itemContract.layers(id, i);
        layerData.push(layer);
    } 
    // let substrateAddress = config.substrateAddress[networkId];
    // let substrateMeta = await getItemMetadata(substrateAddress, 1);

    return layerData;
}

const getEquippableItems = async function ( ) {
    let provider = await ethersService.getProvider();
    let signer = await provider.getSigner();
    let walletAddress = await signer.getAddress();
    let network = await provider.getNetwork();
    let networkId = ethers.utils.hexValue(network.chainId);
    let substrateAddress = config.substrateAddress[networkId];
    let ownedSubstrateIds = await getOwnedItemsPerCollection(provider, walletAddress, substrateAddress);
    let layers = [];
    
    for(let substrate of ownedSubstrateIds){
        let substrateLayers = await getUserLayers(substrateAddress, substrate.tokenId);
        layers.push(substrateLayers);
    }

    let ownedItems = await getOwnedItems();
    return layers;
}

const getItemMetadata = async function (address, id) {
    let provider = await ethersService.getProvider();

    let itemContract = new ethers.Contract(
        address,
        config.composite721ABI,
        provider
      );

    let metadata = await itemContract.tokenURI(id);
    const trimmedMeta = metadata.substring(29);
    const decodedMetadata = Buffer.from(trimmedMeta, 'base64');
    const stringMetadata = decodedMetadata.toString('ascii');

    const parsedMeta = JSON.parse(stringMetadata);
    const imageData = parsedMeta.image.substring(26);
    const decodedImage = Buffer.from(imageData, 'base64');
    const imageString = decodedImage.toString('ascii');

    return {
        "name": await itemContract.name(),
        "symbol": await itemContract.symbol(),
        "image": parsedMeta.image,
        "attributes": parsedMeta.attributes,
        "description": parsedMeta.description
    }
}

const getItemsForSale = async function(){
    let provider = await ethersService.getProvider();
    let network = await provider.getNetwork();
    let networkId = ethers.utils.hexValue(network.chainId);
    
    let multiMintShopAddress = config.multiMintShopAddress[networkId];
    console.log("multiMintShopAddress", multiMintShopAddress);

    let multiMintShopContract = new ethers.Contract(
        multiMintShopAddress,
        config.multiMintShop721ABI,
        provider
      );

    let poolData = [];
    let poolCount = await multiMintShopContract.poolCount();
    for(let i = 0; i < poolCount; i++){
        let pool = {}
        pool.saledata = await multiMintShopContract.pools(i);
        pool.formattedPrice = ethers.utils.formatEther(pool.saledata.price.toString())
        pool.remaining = pool.saledata.cap.sub(pool.saledata.sellCount).toString();
        pool.metadata = await getItemMetadata(pool.saledata.collection, 1);

        poolData.push(pool);
    }

    return poolData;
};

const equipItem = async function( layerTokenId, layerTokenAddress, substrateTokenId, substrateTokenAddress, dispatch ){
    let provider = await ethersService.getProvider();
    let signer = await provider.getSigner();
    let network = await provider.getNetwork();
    let networkId = ethers.utils.hexValue(network.chainId);

    let substrate = new ethers.Contract(
        substrateTokenAddress,
        config.composite721ABI,
        provider
    );
    let layerIndex = await substrate.layerCounts(substrateTokenId);

    let equipTx = await substrate.connect(signer).setLayer(
        substrateTokenId, 
        layerIndex, 
        [ layerTokenAddress, layerTokenId ]
    );
    await dispatch(
        'alert/info',
        {
            message: 'Transaction Submitted',
            metadata: {
            transaction: equipTx.hash
            },
            duration: 300000
        },
        { root: true }
    );
    await equipTx.wait();

    await dispatch('alert/clear', '', { root: true });
        await dispatch(
        'alert/info',
        {
            message: 'Transaction Confirmed',
            duration: 10000
        },
        { root: true }
    );
};

const unEquipItem = async function( layerTokenId, layerTokenAddress, substrateTokenId, substrateTokenAddress, dispatch ){
    let provider = await ethersService.getProvider();
    let signer = await provider.getSigner();
    let network = await provider.getNetwork();
    let networkId = ethers.utils.hexValue(network.chainId);

    let substrate = new ethers.Contract(
        substrateTokenAddress,
        config.composite721ABI,
        provider
    );
    // let layerIndex = await substrate.layerCounts(substrateTokenId);

    let userLayers = await getUserLayers(substrateTokenAddress, substrateTokenId);
    let index = 0;
    for(let layer of userLayers){
        if(layer.item.toLowerCase() == layerTokenAddress.toLowerCase() && layer.id == layerTokenId){
            break; 
        }
        index ++;
    }

    if(index == 0){
        await dispatch(
            'alert/info',
            {
                message: 'Are you sure that item is equipped here?',
                duration: 10000
            },
            { root: true }
        );
        return;
    }

    let rmLayerTx = await substrate.connect(signer).rmLayer(
        substrateTokenId,
        index - 1
    );
    await dispatch(
        'alert/info',
        {
            message: 'Transaction Submitted',
            metadata: {
            transaction: rmLayerTx.hash
            },
            duration: 300000
        },
        { root: true }
    );
    await rmLayerTx.wait();

    await dispatch('alert/clear', '', { root: true });
        await dispatch(
        'alert/info',
        {
            message: 'Transaction Confirmed',
            duration: 10000
        },
        { root: true }
    );
};

const purchaseItem = async function( collectionAddress, index, amount, dispatch ){
    let provider = await ethersService.getProvider();
    let signer = await provider.getSigner();
    let network = await provider.getNetwork();
    let networkId = ethers.utils.hexValue(network.chainId);
    
    let multiMintShopAddress = config.multiMintShopAddress[networkId];
    let multiMintShopContract = new ethers.Contract(
        multiMintShopAddress,
        config.multiMintShop721ABI,
        provider
    );

    let pool = await multiMintShopContract.pools(index);
    
    // double check index matches collection
    console.log("pool", pool);
    if(pool.collection.toLowerCase() != collectionAddress.toLowerCase() ){
        return;
    }
    
    let totalSpend = pool.price.mul(amount);

    let purchaseTx = await multiMintShopContract.connect(signer).mint( 
        amount,
        index,
        {value: totalSpend}
    );

    await dispatch(
        'alert/info',
        {
            message: 'Transaction Submitted',
            metadata: {
            transaction: purchaseTx.hash
            },
            duration: 300000
        },
        { root: true }
    );
    await purchaseTx.wait();

    await dispatch('alert/clear', '', { root: true });
        await dispatch(
        'alert/info',
        {
            message: 'Transaction Confirmed',
            duration: 10000
        },
        { root: true }
    );
};

const getOwnedSubstrates = async function () {
    let provider = await ethersService.getProvider();
    let signer = await provider.getSigner();
    let walletAddress = await signer.getAddress();
    let network = await provider.getNetwork();
    let networkId = ethers.utils.hexValue(network.chainId);

    let substrateAddress = config.substrateAddress[networkId];
    let substrates = await getOwnedItemsPerCollection(provider, walletAddress, substrateAddress);
    //let layers = [];
    for(let substrate of substrates){
        console.log("substratessss", substrate.collectionAddress, substrate.tokenId);
        substrate.userLayers = await getUserLayers(substrate.collectionAddress, substrate.tokenId);
        substrate.layerMetadata = []
        for(let layer of substrate.userLayers){
            let metadata = await getItemMetadata(layer.item, layer.id);
            substrate.layerMetadata.push(metadata);
        }
    }
    return [substrates];
};

const getOwnedItems = async function( collection = null ) {
    let provider = await ethersService.getProvider();
    let signer = await provider.getSigner();
    let walletAddress = await signer.getAddress();

    let network = await provider.getNetwork();
    let networkId = ethers.utils.hexValue(network.chainId);
    let multiMintShopAddress = config.multiMintShopAddress[networkId];
    let multiMintShopContract = new ethers.Contract(
        multiMintShopAddress,
        config.multiMintShop721ABI,
        provider
    );
    if(collection){
        let items = await getOwnedItemsPerCollection(provider, walletAddress, collection);
        return [items];
    }
    console.log('getOWnedItems')
    let ownedItems = [];
    let poolCount = await multiMintShopContract.poolCount();
    for(let i = 0; i < poolCount; i++){
        let pool = await multiMintShopContract.pools(i);
        let items = await getOwnedItemsPerCollection(provider, walletAddress, pool.collection);
        ownedItems.push(items);
    }
    console.log("transfers", ownedItems);
    return ownedItems;
};

const getOwnedItemsPerCollection = async function(provider, walletAddress, collection) {
    let contract = new ethers.Contract(
        collection,
        config.composite721ABI,
        provider
    );

    let ownershipData = {};

    let filterToWallet = contract.filters.Transfer(null, walletAddress);
    let filterFromWallet = contract.filters.Transfer(walletAddress, null);

    let singleTransfers = [
        ...await safeQueryFilter(contract, filterToWallet),
        ...await safeQueryFilter(contract, filterFromWallet)
    ].sort((a,b) => {
        let block = a.blockNumber - b.blockNumber;
        if(block !== 0){
            return block;
        }
        return a.transactionIndex - b.transactionIndex;
    });

    for(const t of singleTransfers){
        ownershipData[t.args.tokenId.toNumber()] = {
            tokenId: t.args.tokenId.toNumber(),
            collectionAddress: t.address,
            owner:  t.args.to
        }
    }

    let ownedItems = Object.values(ownershipData).filter((transfer) => transfer.owner.toLowerCase() === walletAddress.toLowerCase());

    for (let item of ownedItems) {
        let metadata = await getItemMetadata(item.collectionAddress, item.tokenId);
        item.metadata = metadata;

        //check if assigned
        item.equipped  = await contract.assignment(item.tokenId);
        item.svgdata = await contract.svgData(item.tokenId, 2);
    }
    return ownedItems;
};

const safeQueryFilter = async function (contract, event, startBlock = 0, endBlock) {
    let start = startBlock;
    const end = endBlock ?? await contract.provider.getBlockNumber();
    let endRange = end;

    let results = [];
    do {
        if (start >= endRange) {
            endRange = end;
        }
        let singleTransfers = [];
        try {
            singleTransfers = await contract.queryFilter(event, start, endRange);
        } catch (e) {
            const mid = Math.round((start + endRange) / 2);
            endRange = mid;
            continue;
        }
        results = results.concat(singleTransfers);
        start = endRange + 1;
    } while (endRange < end);
    return results;
};

// Export the user service functions.
export const compositeService = {
    equipItem,
    getEquippableItems,
    getItemsForSale,
    getOwnedItems,
    getOwnedSubstrates,
    getUserLayers,
    purchaseItem,
    unEquipItem
  };