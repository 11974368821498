module.exports = class{
	constructor(){
		this.keys = [];

		document.addEventListener.call(window, "keydown", event => {
			if(
				(
					event.key == 'Escape' || 
					event.key == 'ArrowDown' || 
					event.key == 's' || event.key == 'S' ||
					event.key == 'ArrowUp' || 
					event.key == 'w' || event.key == 'W' || 
					event.key == 'ArrowLeft' || 
					event.key == 'a' || event.key == 'A' || 
					event.key == 'ArrowRight' || 
					event.key == 'd' || event.key == 'D' || 
					event.key == ' ' 
				) && this.keys.indexOf(event.key) === -1
			){
				event.preventDefault();
				this.keys.push(event.key);
			}
		});
		document.addEventListener.call(window, "keyup", event => {
			if(
				event.key == 'Escape' || 
				event.key == 'ArrowDown' || 
				event.key == 's' || event.key == 'S' ||
				event.key == 'ArrowUp' || 
				event.key == 'w' || event.key == 'W' ||
				event.key == 'ArrowLeft' || 
				event.key == 'a' || event.key == 'A' ||
				event.key == 'ArrowRight' || 
				event.key == 'd' || event.key == 'D' ||
				event.key == ' ' 
			){
				event.preventDefault();
				this.keys.splice(this.keys.indexOf(event.key),1);
			}
		});
	}
	created(){
		
	}
	
}