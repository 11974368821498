<template>
    <div class="mintSkeletonContainer">
      <Skeletor class="skeleton header"></Skeletor>
    </div>
    <br>

  </template>
  <script>
  import { computed } from 'vue';
  
  import 'vue-skeletor/dist/vue-skeletor.css';
  import { Skeletor } from 'vue-skeletor';
  
  export default {
    components: {
      Skeletor
    },
  
    props: {},
  
    setup(props) {}
  };
  </script>
  <style scoped lang="scss">
  .mintSkeletonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    
  
    .skeleton {
      border-radius: 0;
      width: 100%;
      margin:10px;
      background-color: rgba($color: white, $alpha: 0.2);
    }
  
    .header {
      height: 145px;
      width: 100%;
      
    }
  
    .subtitle {
      height: 40px;
      width: 50%;
      margin-bottom: 20px;
    }
  
    .image {
      height: 250px;
      width: 250px;
      margin-bottom: 30px;
    }
  
    .remaining {
      height: 40px;
      width: 50%;
    }
  
    .dropdown {
      height: 50px;
      width: 60%;
    }
  }
  </style>
  