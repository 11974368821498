import itemABI from './ABIs/itemABI_xxxxxxx.json';
import composite721ABI from './ABIs/composite721.json';
import multiMintShop721ABI from './ABIs/multiMintShop721.json';
import mintShopABI from './ABIs/mintShopABI_xxxxxxx.json';
import merklePreSaleABI from './ABIs/merklePreSaleABI_xxxxxxx.json';

const config = {
  infuraProjectId: '954e2f5992304238b4382298b5e9dea2',
  alchemyApiKey: {
    '0x4': '3UFuUZqwIV6g7mTvIZl30GYIFSkXqh_z',
    '0x5': 'SmRibxWnqII5gXgiuPDRiJT4gzA1vo7t'
  },

  networkPollingIntervals: {
    '0x1': 12000,
    '0x3': 12000,
    '0x4': 12000,
    '0x5': 12000,
    '0x2a': 12000
  },

  paymentSplitter: {
    '0x1': '0x58449889F53b22E35D501ddA2F20A05680eA40e3',
    '0x4': '0xf6ab974e6f43E2E57001D1b2BB0d6E3ECB1eA6F4'
  },

  itemCollections: {
    '0x1': ['0xD44078705456C4f4517A659953f5296e5328F8b8'],
    '0x4': ['0x94849214CA40C6A93d91dc9230265d4593C00810'],
    '0x5': ['0x5712665907eD24347D0aBffeFF2bb6a60EE01512']
  },

  shopAddress: {
    '0x1': '0x384b6066CD1e0aeA770790b3162a754B26ac5f15',
    '0x4': '0x64008da688F44a25d64ae1fd99F9566aF21918B8',
    '0x5': '0x6617CDb31E88F0142C2327668F01A18De838D834'
  },

  merklePreSaleAddress: {
    '0x1': '0xBB25019498c3332424631dfCDC2AF4223df7cED1',
    '0x4': '0xCAA3fa978851aDA84E7677B387C137fe0333914F'
  },

  itemABI,
  mintShopABI,
  merklePreSaleABI,

  substrateAddress: {
    '0x1': '',
    '0x4': '0xA9Da70d82668E503E7dEc5c3F8EbAa068F4B2143',
    '0x5': '0x73342Eba6bF8013EE5d5EBFA44223d93c72Dc0a1'
  },

  multiMintShopAddress: {
    '0x1': '',
    '0x4': '0x045a0963cebCE41ADA93A6C9DF1a49B5Cf97e3F3',
    '0x5': '0x166bd36c6A103F471d1C7b3e63e77004f542900a'
  },

  mintableItems: {
    '0x1': [''],
    '0x4': [''],
    '0x5': ['']
  },

  composite721ABI,
  multiMintShop721ABI,

  reservedMintCount: 444,
  mintPaddingPercent: 2,

  lang: {
    projectName: 'Project Name',
    projectNameLegal: 'Project-Legal-Name',
    whitelistDescriptions: [
      'Whitelist #0 description',
      'Whitelist #1 description',
      'Whitelist #2 description',
    ]
  },

  discordUrl: 'https://discord.gg/some_discord',
  twitterUrl: 'https://twitter.com/some_twitter'
};

export default config;
