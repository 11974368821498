'use strict';

// Imports.
import { compositeService } from '../../services';

// Specified actions for mutating the composite nft state.
export default {
    async getMintableItems({ dispatch, commit }) {
        try {
            let mintableItems = await compositeService.getItemsForSale();
            commit('updateMintableItems', mintableItems);

            // const substrateItem = await compositeService.getSubstrateItem();
            // commit('updateSubstrateItem', substrateItem);
        
            // Catch shop retrieving errors.
        } catch (error) {
            await dispatch('alert/error', error, { root: true });
        }
    },

    async purchaseItem({ dispatch, commit }, {collectionAddress, index, amount}) {
        try {
            let purchase = await compositeService.purchaseItem(collectionAddress, index, amount, dispatch);
            //commit('updateMintableItems', mintableItems);
        
            // Catch shop retrieving errors.
        } catch (error) {
            await dispatch('alert/error', error, { root: true });
        }
    },

    async getOwnedItems({ dispatch, commit }, {collection = null}) {
        try {
            let ownedItems = await compositeService.getOwnedItems(collection);
            commit('updateOwnedItems', ownedItems);
        
            // Catch shop retrieving errors.
        } catch (error) {
            await dispatch('alert/error', error, { root: true });
        }
    },

    async equipItem({ dispatch, commit }, {layerTokenId, layerTokenAddress, substrateTokenId, substrateTokenAddress}) {
        try {
            let equipItem = await compositeService.equipItem(layerTokenId, layerTokenAddress, substrateTokenId, substrateTokenAddress, dispatch);
            //commit('updateMintableItems', mintableItems);
        
            let ownedItems = await compositeService.getOwnedItems();
            commit('updateOwnedItems', ownedItems);
        
            let ownedSubstrates = await compositeService.getOwnedSubstrates();
            commit('updateOwnedSubstrates', ownedSubstrates);
        
            // Catch shop retrieving errors.
        } catch (error) {
            await dispatch('alert/error', error, { root: true });
        }
    },

    async unEquipItem({ dispatch, commit }, {layerTokenId, layerTokenAddress, substrateTokenId, substrateTokenAddress}) {
        try {
            let equipItem = await compositeService.unEquipItem(layerTokenId, layerTokenAddress, substrateTokenId, substrateTokenAddress, dispatch);
            //commit('updateMintableItems', mintableItems);
        
            let ownedItems = await compositeService.getOwnedItems();
            commit('updateOwnedItems', ownedItems);
        
            let ownedSubstrates = await compositeService.getOwnedSubstrates();
            commit('updateOwnedSubstrates', ownedSubstrates);
        
            // Catch shop retrieving errors.
        } catch (error) {
            await dispatch('alert/error', error, { root: true });
        }
    },

    async getOwnedSubstrates({ dispatch, commit }) {
        try {
            let ownedSubstrates = await compositeService.getOwnedSubstrates();
            commit('updateOwnedSubstrates', ownedSubstrates);
        
            // Catch shop retrieving errors.
        } catch (error) {
            await dispatch('alert/error', error, { root: true });
        }
    },

    async getEquippableItems({ dispatch, commit }) {
        try {
            let equippableItems = await compositeService.getEquippableItems();
            commit('updateEquippableItems', equippableItems);
        
            // Catch shop retrieving errors.
        } catch (error) {
            await dispatch('alert/error', error, { root: true });
        }
    },
};
