const Projectile = require('./Projectile');
const types = [
	{
		name: 'wave1',
		speed: 2,
		image: 'images/enemy1.png',
		pointValue: 25,
		projectileColor: '#f08',
		projectileCooldown: 150,
		projectileRate: 50
	},
	{
		name: 'wave2',
		speed: 3,
		image: 'images/enemy2.png',
		pointValue: 50,
		projectileColor: '#ff8',
		projectileCooldown: 60,
		projectileRate: 40
	},
	{
		name: 'wave3',
		speed: 4,
		image: 'images/enemy3.png',
		pointValue: 75,
		projectileColor: '#0f0',
		projectileCooldown: 30,
		projectileRate: 30
	},
	{
		name: 'wave4',
		speed: 6,
		image: 'images/enemy4.png',
		pointValue: 100,
		projectileColor: '#0fb',
		projectileCooldown: 40,
		projectileRate: 20
	},
	{
		name: 'wave5',
		speed: 6,
		image: 'images/enemy5.png',
		pointValue: 125,
		projectileColor: '#ff4',
		projectileCooldown: 30,
		projectileRate: 30
	},
	{
		name: 'wave6',
		speed: 7,
		image: 'images/enemy6.png',
		pointValue: 150,
		projectileColor: '#ffE',
		projectileCooldown: 40,
		projectileRate: 30
	},
	{
		name: 'wave7',
		speed: 7,
		image: 'images/enemy7.png',
		pointValue: 175,
		projectileColor: '#00F',
		projectileCooldown: 40,
		projectileRate: 30
	},
	{
		name: 'wave8',
		speed: 8,
		image: 'images/enemy8.png',
		pointValue: 200,
		projectileColor: '#F0F',
		projectileCooldown: 40,
		projectileRate: 40
	}

]
module.exports = class {
	constructor(context, gameWidth, gameHeight, offsetX, offsetY, wave){
        this.context = context;
		
		this.wave = wave;
		if(this.wave > types.length){
			this.wave = this.wave % types.length;
			this.lap = Math.floor(this.wave / types.length)
		}

		this.type = types[this.wave] ? types[this.wave] : types[types.length-1];
		this.speed = this.type.speed;
		this.pointValue = this.type.pointValue;
		this.projectileColor = this.type.projectileColor;
		this.projectileCooldown = this.type.projectileCooldown;
        this.projectileRate = this.type.projectileRate;

        
		this.gameWidth = gameWidth;
        this.gameHeight = gameHeight;
        this.width = 100;
        this.height = 100;
        this.x = this.gameWidth + offsetX;
        this.y = offsetY;
        
        
        this.markedForDeletion = false;

        this.cycleLimit = 100;
        this.cycleRate = 40;
        this.direction = 1;
		
		
    }
    draw(){
        // this.context.strokeStyle="red";
        // this.context.beginPath();
        // this.context.arc(this.x, this.y, this.width/2, 0, Math.PI*2);
        // this.context.stroke();

        let enemyShip = new Image();
        enemyShip.src = this.type.image;

        // this.context.globalCompositeOperation='destination-over';
		this.context.shadowColor=this.projectileColor;
		this.context.shadowBlur=30;
        this.context.drawImage(enemyShip, this.x - this.width/2, this.y - this.height/2, this.width, this.height);
        this.context.shadowBlur=0;
    //     this.context.fillStyle="blue";
        // this.context.beginPath();
        // this.context.arc(this.x , this.y , this.width/2, 0, Math.PI*2);
        // this.context.strokeStyle="yellow";
        // this.context.stroke();
    }
    update(projectiles){
        this.x -= 3 * this.speed;
        
        if(this.cycleRate > 80 + (Math.random() * this.cycleLimit)){
            
            this.cycleRate = 0;
            this.direction = this.direction * -1;
        }else{
			this.direction = this.direction * 1.008;
            this.y -= this.direction * this.speed;
            this.cycleRate++;
        }

            


        this.markedForDeletion = (this.x < 0 - this.width) ? true : false;

        if(this.projectileCooldown == 0){
            let xSp = (Math.random() * 10 * -1 -1);
            let ySp = Math.random() * 2;
            let projectile = new Projectile(this.context, this.x + this.width, this.y + this.height, this.projectileColor, xSp, ySp, 'enemy');
            projectiles.push(projectile);
            // let projectile2 = new Projectile(this.context, this.x + this.width, this.y + this.height, '#f0f', xSp+1, ySp+1, 'enemy');
            // projectiles.push(projectile2);
            this.projectileCooldown = this.projectileRate;
        }else if(this.projectileCooldown > 0){
            this.projectileCooldown--;
        }
    }
	changeDirection(){
		this.direction = this.direction * -1;
	}
}