module.exports = class {
	constructor(context, x, y, origin, pointValue){
        this.context = context;
        this.x = x;
        this.y = y;
        this.width = 96;
        this.height = 96;
        this.origin = origin;
        this.frameSpeed = 4;
        this.frame = 0;
        this.frameLimit = 8;
        this.fps = 20;
        this.frameTimer = 0;
        this.frameInterval = 400/this.fps;
        this.image = new Image();
		this.pointValue = pointValue;
        
        this.markedForDeletion = false;
        if(origin == 'enemy'){
            this.image.src = 'images/explosion_sm.png';
        }else if (origin == 'player'){
            this.image.src = 'images/explosion_player256.png';
            this.frameLimit = 16;
            this.width = 256;
            this.height = 256;
        }
    }

    draw(context){
		context.globalCompositeOperation='destination-over';
		context.shadowColor=`rgb(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)})`;
		context.shadowBlur=30;
        context.drawImage(this.image, this.frame * this.width, 0, this.width, this.height, this.x - this.width/2, this.y - this.height/2, this.width, this.height);
		context.fillStyle = `rgb(256, 0, ${256 - (this.frame * 24)})`;
        context.font = '26px DeterminationMono';
        context.fillText(this.pointValue * 56, this.x - 30 , this.y - this.height/2 -(this.frame*6));
		context.shadowBlur=0;
    }

    update(){
        if(this.frameTimer > this.frameInterval){
            this.frame += 1;
            this.frameTimer = 0;
			
            if(this.frame >= this.frameLimit){
                this.markedForDeletion = true;
                this.frame = 0;
            }
        }else{
            this.frameTimer += 10; 
        }

    }
}