'use strict';

// Specified state transitions per particular actions.
export default {
    updateEquippableItems(state, equippableItems) {
        state.equippableItems = equippableItems;
    },
    updateMintableItems(state, mintableItems) {
        state.mintableItems = mintableItems;
    },
    updateOwnedItems(state, ownedItems) {
        state.ownedItems = ownedItems;
    },
    updateOwnedSubstrates(state, ownedSubstrates) {
        state.ownedSubstrates = ownedSubstrates;
    },
    updateSubstrateItem(state, substrateItem) {
        state.substrateItem = substrateItem;
    },
    
};
