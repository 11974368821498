<template>
  <Ethereum :callback="initialize" />
</template>
<script>
import Ethereum from '/src/components/common/Ethereum.vue';

import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
    Ethereum,
  },

  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => {
      return store.state.ethers.address;
    });

    const initialize = async () => {zz};

    return {
      store,
      isLoggedIn,
      initialize
    };
  }
};
</script>
<style scoped lang="scss">
.home {
  text-align: center;
  padding: 50px;
}
</style>
