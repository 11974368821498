
let game;


let loading = false;
let debug = true;

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const InputHandler = require('./class/InputHandler');
const Player = require('./class/Player');
const PowerUp = require('./class/PowerUp');
const Enemy = require('./class/Enemy');
const Layer = require('./class/Layer');

const wave = [
	{
		enemyPerWave: 20,
		enemyPerGroup: 5,
		enemyId: 0,
		interval: 500,
		background: 'default'
	},
	{
		enemyPerWave: 40,
		enemyPerGroup: 4,
		enemyId: 1,
		interval: 500,
		background: 'images/midnight.png'
	},
	{
		enemyPerWave: 60,
		enemyPerGroup: 5,
		enemyId: 2,
		interval: 500,
		background: 'images/blue.png'
	},
	{
		enemyPerWave: 50,
		enemyPerGroup: 7,
		enemyId: 3,
		interval: 500,
		background: 'default'
	},
	{
		enemyPerWave: 50,
		enemyPerGroup: 9,
		enemyId: 4,
		interval: 500,
		background: 'default'
	},
	{
		enemyPerWave: 50,
		enemyPerGroup: 10,
		enemyId: 5,
		interval: 500,
		background: 'default'
	},
	{
		enemyPerWave: 50,
		enemyPerGroup: 10,
		enemyId: 6,
		interval: 200,
		background: 'default'
	},
	{
		enemyPerWave: 50,
		enemyPerGroup: 10,
		enemyId: 6,
		interval: 100,
		background: 'default'
	},
	{
		enemyPerWave: 5000,
		enemyPerGroup: 10,
		enemyId: 7,
		interval: 100,
		background: 'default'
	}
];

export class Game {
    constructor( context, width, height, playerImage, playerAttributes ){
        this.context = context;
        this.width = width;
        this.height = height;

		this.score = 0;
		this.waveId = 0;
		this.wave = {};

        this.player = null;
        this.playerImage = playerImage;
        this.playerAttributes = playerAttributes;
        this.projectiles = [];
        this.enemies = [];
        this.explosions = [];

		this.enemiesDestroyedCount = 0;
		this.totalEnemiesDestroyedCount = 0;
		
		this.totalProjectilesFired = 0;

		this.powerups = [];
        
		this.gameOver = false;

        let back1 = new Image();
        back1.src = 'images/back1sm2.png';
        let back2 = new Image();
        back2.src = 'images/back2sm2.png';
        let back3 = new Image();
        back3.src = 'images/back3sm2.png';
        this.gameSpeed = 8;
        // console.log('player', playerImage);

        const layer1 = new Layer(back2, this.gameSpeed, 1.4, width, height);
        const layer2 = new Layer(back1, this.gameSpeed, 2.1, width, height);
        const layer3 = new Layer(back3, this.gameSpeed, .7, width, height);
        
        this.input = new InputHandler();
        this.player = new Player(this.context, this.width, this.height, playerImage, playerAttributes);
        this.layers = [ layer1, layer2 , layer3];
    
        this.lastTime = 0;
        this.enemyTimer = 0;
        this.enemyInterval = 1000;
        this.randomEnemyInterval = Math.random() * 1000; 

		this.audio = {
			soundtrack: new Audio(),
            explosion: {
                gameOver: new Audio(),
                player: new Audio()
            }
        }
        this.audio.soundtrack.src = 'audio/soundtrack_10.mp3';
        this.audio.soundtrack.play();
        this.audio.soundtrack.volume = 1.0;
        this.audio.soundtrack.loop = true;

		this.audio.explosion.gameOver.src = 'audio/explosion_nuked.mp3';
		this.audio.explosion.gameOver.volume = 0.5;
        this.audio.explosion.player.src = 'audio/explosion_bigbang.mp3';
		this.audio.explosion.player.volume = 0.5;
    }

    displayText (ctx, color, font, text, x, y) {
        ctx.fillStyle = color;
        ctx.font = font;
        ctx.fillText(text, x, y);
    }

    handleEnemies (ctx, deltaTime) {
        if(this.enemyTimer > this.enemyInterval + this.randomEnemyInterval){
            let new_Y  = (Math.random() * (this.height - 200)) + 100;
			let enemyId = this.waveId % wave.length;
			// for(let j=0;j <= this.waveId; j++ ){
				for(let i=0;i<this.wave.enemyPerGroup;i++){
					let direction = new_Y > this.height / 2 ? -1 : 1;
					let offset = (i) * 100;
					this.enemies.push(new Enemy(ctx, this.width, this.height, offset, new_Y+offset * direction, enemyId));
				}
			// }

            // this.enemies.push(new Enemy(ctx, this.width, this.height, 300, new_Y+120));
            this.randomEnemyInterval = Math.random() * 1000 + 500;
            this.enemyTimer = 0;
        } else {
            this.enemyTimer += deltaTime;
        }
		
        
        this.enemies.forEach(enemy => {
			if(
				enemy.y - enemy.height < 0 ||
				enemy.y + enemy.height > this.height
			) {
				enemy.changeDirection();
			}

            enemy.draw(ctx);
            enemy.update(this.projectiles);
        });
        this.enemies = this.enemies.filter(enemy => !enemy.markedForDeletion);
		// this.enemies.forEach((enemy, index) => {
		// 	if(enemy.markedForDeletion) {
				
        //         this.enemies.splice(index, 1)
        //     }
		// });
		// 
    }

    animate (timeStamp) {
		this.wave = wave[this.waveId % wave.length];
		
        const deltaTime = timeStamp - this.lastTime;
        this.lastTime = timeStamp;
		
        this.context.clearRect(0, 0, this.width, this.height);

		// if(this.wave.background !== 'default'){
		// 	let background = new Image();
		// 	background.src = this.wave.background;
		// 	let newLayer = new Layer(background, 0, 0, this.width, this.height);
		// 	this.layers = [...this.d, newLayer]
		// 	this.wave.background = 'default'
		// }
        if(this.player){
            this.player.draw(this.context);
            this.player.update(this.input, this.enemies, this.projectiles, this.explosions);

            if(this.player.destroyed){
                console.log("destroyed"); 
                this.audio.soundtrack.pause();
                this.gameOver = true;
                this.player = null;
				this.audio.explosion.player.currentTime = 0; 
				this.audio.explosion.player.play();
				this.audio.explosion.gameOver.currentTime = 0; 
				this.audio.explosion.gameOver.play();  
            }
        }
        
        this.projectiles.forEach((projectile, index) => {
            projectile.draw();
            projectile.update(this.player, this.enemies, this.explosions);
            if(
                projectile.x + projectile.width < 0 ||
                projectile.x - projectile.width > this.width ||
                projectile.y + projectile.height < 0 ||
                projectile.y - projectile.height > this.height ||
                projectile.madeContact
            ) {
				if(projectile.origin !== 'enemy'  ){
					this.totalProjectilesFired += 1;
				}
                this.projectiles.splice(index, 1)
            }
        });

        this.explosions.forEach((explosion, index) => {
            explosion.draw(this.context);
            explosion.update(this.explosions);
			// console.log("explosion", explosion.pointValue);
			this.score += explosion.pointValue;
            if(explosion.markedForDeletion) {
				if(this.enemiesDestroyedCount >= this.wave.enemyPerWave){
					this.waveId += 1;
					this.enemiesDestroyedCount = 0;
					this.powerups.push( new PowerUp(explosion.x, explosion.y));
				}else{
					this.enemiesDestroyedCount++;
				}
				this.totalEnemiesDestroyedCount++;
                this.explosions.splice(index, 1)
            }
			
        });

        this.handleEnemies(this.context, deltaTime);


		this.powerups.forEach((powerup, index) => {
			powerup.update(this.player)
			powerup.draw(this.context)
			if(
				powerup.x + powerup.width < 0 ||
                powerup.x - powerup.width > this.width ||
                powerup.y + powerup.height < 0 ||
                powerup.y - powerup.height > this.height ||
				powerup.markedForDeletion
			){	
				if(powerup.received){
					this.player.receivePowerup(powerup);
				}
				this.powerups.splice(index, 1)
			}
		});

        this.context.shadowColor=`rgb(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 128)},${Math.floor(Math.random() * 32)})`;
		this.context.shadowBlur=25;
        this.displayText(this.context, "yellow", "80px DeterminationMono", this.score, 40, 80);
		if(debug){
			this.displayText(this.context, "red", "20px DeterminationMono", `Enemy Count: ${this.enemies.length}`, 40, 170);
			this.displayText(this.context, "cyan", "20px DeterminationMono", `Projectile Count: ${this.projectiles.length}`, 40, 200);
			this.displayText(this.context, "magenta", "20px DeterminationMono", `Enemies Destroyed this Wave: ${this.enemiesDestroyedCount}`, 40, 240);
			this.displayText(this.context, "magenta", "20px DeterminationMono", `Wave: ${this.waveId}`, 40, 270);
			this.displayText(this.context, "purple", "20px DeterminationMono", `Projectiles Fired: ${this.totalProjectilesFired}`, 40, 300);
			this.displayText(this.context, "orange", "20px DeterminationMono", `Total Enemies Destroyed: ${this.totalEnemiesDestroyedCount}`, 40, 330);
			this.displayText(this.context, "green", "20px DeterminationMono", `Powerups: ${this.powerups.length}`, 40, 360);
			
			this.displayText(this.context, "cyan", "80px DeterminationMono", `${Math.floor(timeStamp / 1000)}s`, 40, 960);
		}
        this.context.shadowBlur=0;
        if(this.gameOver){
			this.context.shadowColor=`rgb(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 64)},${Math.floor(Math.random() * 32)})`;
			this.context.shadowBlur=25;
            this.displayText(this.context, "red", "140px DeterminationMono", "GAME OVER", this.width/4, this.height/2);
            this.displayText(this.context, "red", "40px DeterminationMono", "Press ESC to restart", this.width/3, this.height - this.height/3);
			this.context.shadowBlur=0;
            // if(!loading){
            //     this.resetGame();
            // }
            
            
        }
        //if(!gameOver){
            requestAnimationFrame(this.animate.bind(this));
        //}
        
        if(this.gameOver && this.input.keys.indexOf('Escape') > -1){
            if(!loading){
                this.resetGame();
                
            }
        }
		if(this.gameOver && this.input.keys.indexOf(' ') > -1){
            if(!loading){
                this.continueGame();
                
            }
        }
					
        this.layers.forEach(layer => {
            layer.update(5*(2 + (.5 * this.waveId)));
            layer.draw(this.context);
        });
		
    }

    async resetGame () {
        loading = true;
        //await sleep(5000);
        this.gameOver = false;
        
        this.enemiesDestroyedCount = 0;
		this.totalEnemiesDestroyedCount = 0;
		this.totalProjectilesFired = 0;
        
        this.projectiles = [];
        this.enemies = [];
        this.score = 0;
		this.waveId = 0;
        // console.log("player", this.player);

        this.player = new Player(this.context, this.width, this.height, this.playerImage, this.playerAttributes);
        // console.log("player", this.player);
        this.audio.soundtrack.currentTime = 0; 
        this.audio.soundtrack.play();
        loading = false;
    }

	async continueGame(){
		loading = true;
        //await sleep(5000);
        this.gameOver = false;

        this.projectiles = [];
        this.enemies = [];
        // this.score = 0;
        // console.log("player", this.player);
		this.audio.soundtrack.play();

        this.player = new Player(this.context, this.width, this.height, this.playerImage, this.playerAttributes);
        // console.log("player", this.player);
        loading = false;
	}

    startGame () {
        // console.log("start");
        this.animate(0);
    }
}

export default function start ( context, width, height, playerImage, playerAttributes ) {
    // console.log("start", playerImage);
    game = new Game(context, width, height, playerImage, playerAttributes);
    game.startGame();
}