<template>
  <Ethereum :callback="initialize" />
  <div v-if="!isConnected" style="margin-top:100px;">
    <ConnectWallet />
  </div>
  <div v-else-if="isLoading" class="skeleton">
    <div class="main">
      <div class="saleGrid">
        <div class="saleItem" v-for="(number, index) in 10" :key="index">
          <MintSkeleton />
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="!isLoading && isConnected" class="main">
      <div class="saleGrid">
          <div 
              v-for="(item, index) in itemsForSale" 
              :key="index"
              class="saleItem"
              >
              <div class="itemCell">
                <img :src="item.metadata.image" class="smallThumb" />
              </div>
              
              <div class="itemInfo">
                  <h3>{{item.metadata.name}}</h3>
                  <div class="price">{{item.formattedPrice}} ETH</div>
                  <div >{{item.remaining}} remaining</div>
              </div>
              <div class="itemCell">
                <Button @click="openMintModal(index)">Mint</Button>
              </div>
          </div>
      </div>
  </div>
  <div v-if="showMintModal">
    <div class="mintBlackout">
      <div class="mintModal">
        <div class="closeButton" @click="toggleModal(false)">X</div>
        <h2>[{{selectedCollection.metadata.symbol}}] {{selectedCollection.metadata.name}} </h2><br>
        <p>{{ selectedCollection.metadata.description}}</p>
        <br>
        <div class="attributeList">
            <div v-for="(trait, index) in selectedCollection.metadata.attributes" 
              :key="index"
              >
              <div class="attrWrap" v-if="trait.trait_type != 'Identifier'">
                <div class="attrType">{{trait.trait_type}}</div>
                <div class="attrValue">{{trait.value}}</div>
              </div>
            </div>
        </div>
        <br>
        <div class="mintModalInfo">
          <div class="modalCell">
            <img :src="selectedCollection.metadata.image" class="medThumb" />
          </div>
          
          <div class="modalCell" >
            Price: {{selectedCollection.formattedPrice}} ETH
            <DropdownMenu
              class="dropdown"
              :selection="mintQty"
              :items="allowances"
              @selected="handleSelection(index, $event, 1)"
            />
            <br>
            Total: {{ selectedCollection.formattedPrice * mintQty}} ETH
            <div>
              <Button class="buttonWidth" @click="purchaseItem()">Mint</Button>
            </div>
            
          </div>
    
          
        </div>
<!--         
        {{selectedCollection}} -->
      </div>
    </div>
  </div>
</template>
<script>
// Imports.
// import { computed, onMounted, ref, watch } from 'vue';
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
// import { log } from '/src/utility';

// // Component imports.
// import DropdownMenu from '/src/components/ui/DropdownMenu.vue';
import Button from '/src/components/ui/Button.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
// import Countdown from '/src/components/ui/Countdown.vue';
// import ConnectWallet from '/src/components/common/ConnectWallet.vue';
// import TabItem from '/src/components/ui/TabItem.vue';
import MintSkeleton from './components/MintSkeleton.vue';
import DropdownMenu from '../../components/ui/DropdownMenu.vue';
// import config from '/src/config'

export default {
  components: {
    // DropdownMenu,
    Button,
    Ethereum,
    // Countdown,
    // ConnectWallet,
    // TabItem,
    MintSkeleton,
    DropdownMenu
},

  setup() {
    const store = useStore();

    const isConnected = computed(() => {
      return store.state.ethers.canSign;
    });

    const isLoading = ref(true);

    const itemsForSale = computed(() => {
      return store.state.composite.mintableItems;
    });

    const allowances = computed(() => {
      return [1,2,3,4,5];
    });
  
    const initialize = async () => {
      await store.dispatch(
        'composite/getMintableItems',
        { },
        { root: true }
      );
      isLoading.value = false;
      console.log(store.state.composite.mintableItems);
    };

    const showMintModal = ref(false);

    const toggleModal = async ( state ) => {
      showMintModal.value = state;
    }

    const openMintModal = async ( index ) => {
      console.log("MINT", index);

      let collection = itemsForSale.value[index]
      selectedCollection.value = collection;
      selectedCollectionIndex.value = index;
      showMintModal.value = true;
    };
    const mintQty = ref(1);
    const handleSelection = (index, selection) => {
      //whiteListId = selection;
      console.log('mintQty.value', selection, mintQty.value);
      mintQty.value = selection;
    };

    const selectedCollection = ref('0x0');
    const selectedCollectionIndex = ref('0');
    const purchaseItem = async () => {
      console.log("MINT");
      await store.dispatch(
        'composite/purchaseItem',
        { 
          collectionAddress: selectedCollection.value.saledata.collection,
          index: selectedCollectionIndex.value,
          amount: mintQty.value
        },
        { root: true }
      );
    };

    return {
      allowances,
      handleSelection,
      isConnected,
      isLoading,
      itemsForSale,
      initialize,
      mintQty,
      openMintModal,
      purchaseItem,
      selectedCollection,
      selectedCollectionIndex,
      showMintModal,
      toggleModal
    };
  }
};
</script>
<style scoped lang="scss">

.skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main {
    width:80%;
    margin:40px auto;
  }
  .smallThumb {
    width:100px;
    height:100px;
  }  
  .medThumb {
    width:250px;
    height:250px;
  }
  .saleItem {
    margin:10px 40px;
    padding:10px;
    display:flex;
    flex-direction:row;
    background:rgb(0,0,0);
  }
  .itemCell {
    width:140px;
    padding:10px 5px;
  }
  .itemInfo {
    width:300px;
  }
  
  .modalCell {
    width:300px;
    margin:auto;
  }
  .attributeList {
    width:720px;
    margin:auto;
    flex-direction:row;
  }
  .saleGrid {
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
  }
  .mintModal {
    background:rgb(0,0,0);
    margin:200px auto;
    width: 720px;
    padding:20px;
    z-index:111;
    border:2px solid red;

  }
  .mintModalInfo {
    display:flex;
    flex-direction:row;
  }
  .mintBlackout {
    background:rgba(0,0,0,.8);
    position:fixed;
    top:0px;
    width:100%;
    height:100%;

  }
  .closeButton {
    float:right;
    cursor:pointer;
  }

  .buttonWidth {
    width:200px;
  }

  .attrWrap {
    width:680px;
    border:1px solid rgb(0,0,100);
    margin:5px 0;
  }
  .attrType {
    display:inline-block;
    width:50%;
    padding:5px;
  }
  
  .attrValue {
    display:inline-block;
    width:50%;
    padding:5px;
  }
</style>
