const Explosion = require('./Explosion');

module.exports = class {
	constructor(context, x, y, color, speedX, speedY, origin){
        this.context = context;
        this.x = x;
        this.y = y;
        this.width = 70;
        this.height = 8;
        this.speedX = speedX;
        this.speedY = speedY;
        this.origin = origin;
        this.image = new Image();
        this.image.src = 'images/projectile_laser.png';
        this.madeContact = false;
		this.color = color;
        this.audio = {
            weapon: new Audio(),
            explosion: {
                gameOver: new Audio(),
                player: new Audio(),
                enemy: new Audio()
            }
        }
        this.audio.weapon.src = 'audio/blaster_zap.mp3';
		this.audio.weapon.volume = 0.3;
        if (origin == 'enemy') {
            this.audio.weapon.src = 'audio/blaster_plasma.mp3';
        }


        this.audio.explosion.enemy.src = 'audio/explosion_oomph.mp3';
        this.audio.explosion.enemy.volume = 0.3;
        this.audio.weapon.play();
    }
    draw(){
		this.context.globalCompositeOperation='destination-over';
		if(this.origin == 'player' || this.origin == 'drone'){
			this.context.fillStyle=this.color;
			this.context.shadowColor=`rgb(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)})`;
			this.context.shadowBlur=10;
			this.context.fillRect(this.x, this.y, this.width, this.height);
			// this.context.shadowColor=`rgb(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)})`;
			this.context.shadowBlur=0;
		} else if(this.origin == 'enemy'){
			this.context.fillStyle=this.color;
			this.context.shadowColor=`rgb(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)})`;
			this.context.shadowBlur=10;
			this.context.fillRect(this.x, this.y, this.width/2, this.width/2);
			// this.context.shadowColor=`rgb(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)})`;
			this.context.shadowBlur=0;
		}

    }
    update(player, enemies, explosions){
        this.x += 2 * this.speedX;
        this.y += 2 * this.speedY;
        // collisions
        if(this.origin == 'player' || this.origin == 'drone'){

            enemies.forEach((enemy, index) => {
                const dx = enemy.x - this.x;
                const dy = enemy.y - this.y;
                const distance = Math.sqrt(dx*dx + dy*dy);
    
                if(distance < enemy.width/2 + this.width/2){
                    // got em
                    enemies.splice(index, 1);
                    this.madeContact = true;
    
                    this.audio.explosion.enemy.play();
                    let explode = new Explosion(this.context, this.x, this.y, 'enemy', enemy.pointValue);
                    explosions.push(explode);
                }
            });
			
        }else if(this.origin == 'enemy'){
            if(player){
                const dx = player.x - this.x;
                const dy = player.y - this.y;
                const distance = Math.sqrt(dx*dx + dy*dy);
    
                if(distance < player.width/4 + this.width/2){
                    // got em
                    player.destroyed = true;
                    this.madeContact = true;
                    
					let explode = new Explosion(this.context, this.x, this.y, 'player', 0);
                    explosions.push(explode);
                }
            }
            
        }
        
    }
}