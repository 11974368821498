const Projectile = require('./Projectile');
const Explosion = require('./Explosion');

module.exports = class {
    constructor(context, gameWidth, gameHeight, image, attributes){
        this.context = context;
        this.gameWidth = gameWidth;
        this.gameHeight = gameHeight;
        this.width = 200;
        this.height = 200;
        this.x = 100;
        this.y = 250;
        //this.image = image;
        
        this.image = new Image();
        let blob = new Blob([image], {type: 'image/svg+xml'});
        let url = URL.createObjectURL(blob);
        this.image.src = url;
        
        this.attributes = attributes;
        this.speed = 5*2;
        
        if(attributes.Engine.length > 0){
            this.speed = +attributes.Engine[0][3].value * 2; 
        }
        this.speedX = 0;
        this.speedY = 0;

        this.projectileBurst = 0
        this.projectileCount = 1;
        if(attributes.Weapon.length > 0){
            this.projectileBurst = +attributes.Weapon[0][3].value
            this.projectileCount = +attributes.Weapon[0][4].value
        }  

        this.projectileCooldown = 0;
        this.projectileRate = 15 / (1 + this.projectileBurst);

        this.hasDrone = false;
        if(attributes.Drone.length > 0){
            this.hasDrone = true;
        }

        this.destroyed = false;
        this.activePowerup = null;
		this.powerupTimer = 0;
    }
    draw(ctx){
		ctx.shadowColor=`rgb(200,200,50)`;
		ctx.shadowBlur=50;
        ctx.globalCompositeOperation='destination-under';
        ctx.drawImage(this.image, this.x - this.width/2, this.y - this.height/2, this.width, this.height);
        ctx.shadowBlur=0;
    //     this.context.fillStyle="blue";
        // this.context.beginPath();
        // this.context.arc(this.x , this.y , this.width/2, 0, Math.PI*2);
        // this.context.strokeStyle="yellow";
        // this.context.stroke();
    }
    update(input, enemies, projectiles, explosions){

        // collisions
        enemies.forEach(enemy => {
        const dx = enemy.x - this.x;
        const dy = enemy.y - this.y;
        const distance = Math.sqrt(dx*dx + dy*dy);

        if(distance < enemy.width/2 + this.height/4){
            // gameOver = true;
			let explode = new Explosion(this.context, this.x, this.y, 'player', 0);
			explosions.push(explode);
			explode = new Explosion(this.context, this.x, this.y, 'enemy', enemy.pointValue);
			explosions.push(explode);
            this.destroyed = true;
            // console.log({
            // "enemy x": enemy.x,
            // "this x": this.x,
            // "dx": dx,
            // "enemy y": enemy.y,
            // "this y": this.y,
            // "dy": dy,
            // "distance": distance
            // });
        }
        });

        // control
        this.x += this.speedX;
        if(input.keys.indexOf('ArrowRight') > -1 || input.keys.indexOf('d') > -1){
            // console.log("this.speed", this.speed)
            this.speedX = this.speed;
        } else if (input.keys.indexOf('ArrowLeft') > -1 || input.keys.indexOf('a') > -1) {
            this.speedX = this.speed * (-1);
        } else {
            this.speedX = 0;
        }

        if(this.x < 0) {
            this.x = 0;
        } else if (this.x > this.gameWidth) {
            this.x = this.gameWidth;
        }

        this.y += this.speedY;
        if(input.keys.indexOf('ArrowUp') > -1 || input.keys.indexOf('w') > -1){
            this.speedY = 0 - this.speed;
        } else if (input.keys.indexOf('ArrowDown') > -1 || input.keys.indexOf('s') > -1) {
            this.speedY = this.speed;
        } else {
            this.speedY = 0;
        }

        if(this.y < 0) {
            this.y = 0;
        } else if (this.y > this.gameHeight) {
            this.y = this.gameHeight;
        }

        if(input.keys.indexOf(' ') > -1){
        if(this.projectileCooldown == 0){
            // for(let i = 0; i < this.projectileBurst; i++){
                for(let j = 0; j < this.projectileCount; j++){
                    let projectile = new Projectile(this.context, this.x + this.width/3, this.y + this.height/4 + (j * 50), '#0f0', 10, 0, 'player');
                    projectiles.push(projectile);
                }
            // }
            if(this.hasDrone){
                let projectile = new Projectile(this.context, this.x + this.width/3, this.y - this.height/3, '#f50', 15, 0, 'drone');
                projectiles.push(projectile);
            }
            
            this.projectileCooldown = this.projectileRate;
            }else if(this.projectileCooldown > 0){
                this.projectileCooldown--;
            }
        }

		if(this.activePowerup){
			if(this.powerupTimer > this.activePowerup.duration){
				this.activePowerup = null;
				this.powerupTimer = 0;
				this.resetSpeed();
				this.resetWeapons();
			}else{
				this.powerupTimer++;
			}
			
		}
    }
	receivePowerup(powerup){
		console.log(`POWERED UP WITH ${powerup.name}`);
		this.activePowerup = powerup;
		if(powerup.name == 'rapid_fire'){
			this.projectileRate = 5;
		}else if(powerup.name == 'speed'){
			console.log("speed?", this.speed, powerup)
			this.speed = powerup.multiplier;
		}
	}
	resetSpeed(){
		if(this.attributes.Engine.length > 0){
            this.speed = +this.attributes.Engine[0][3].value * 2; 
        } else {
			this.speed = 5;
		}
	}
	resetWeapons(){
		this.projectileRate = 30 / (1 + this.projectileBurst);
	}
}