<template>
    <Ethereum :callback="initialize" />
    <Button v-show="false"></Button>
    <div v-if="!isConnected" style="margin-top:100px;">
      <ConnectWallet />
    </div>
    <!-- <div v-else-if="isLoading" class="skeleton">
      <div class="main">
        <EquipSkeleton />
      </div>
    </div> -->
    <div v-else-if="isConnected" class="primary">
      
      <div class="secondary left"> 
        <div>FLEET</div>
        <div v-if="isLoading" class="skeleton">
          <div class="main">
            <EquipSkeleton v-for="(number, index) in 10" :key="index"/>
            
          </div>
        </div>
        <div v-else-if="!isLoading">
          <div
            v-for="(collection, index) in ownedSubstrates" 
            :key="index"
          >
            <div
              v-for="(item, index2) in collection" 
              :key="index2"
            > 
              <div @click="selectItem(index, index2)">
                <div class="selectCell">
                  <img :src="item.metadata.image" class="smThumb" />
                </div>
                <div class="selectCell">
                  <!-- {{item.metadata.name}} <br> -->
                  {{item.metadata.symbol}} {{item.tokenId}}
                </div>
              </div>
              
            </div>
          </div>
        </div>

      </div>
      <div class="secondary right"> 
        <div class ="currentItem">
          <div class="currentItemImage">
            
            <img :src="currentItem.metadata.image" class="lgThumb" />
          </div>
          <div class="currentItemEquip">
            
            <!-- {{currentItem.metadata.name}} #{{currentItem.metadata.attributes[0].value}} -->
            <!-- {{currentItem.metadata.name}} {{currentItem.metadatad}} -->
            <!-- {{currentItem.layerMetadata}} -->
            <div 
              v-for="(meta, index) in currentItem.layerMetadata"
              :key="index"
            >
              <!-- {{meta}} -->
              <div class="equippedRow unequipButton" @click="unEquipItem(currentItem.tokenId, currentItem.collectionAddress, selectedSubstrateId, selectedSubstrateAddress)">[X]</div>
              
              <img class="smThumb" :src="meta.image" />
              <div class="equippedRow">[{{meta.symbol}}] {{meta.name}}</div>
              <div 
                v-for="(trait, index2) in meta.attributes"
                :key="index2"
                class="equippedRow"
              >
                <div class =" attributes" v-if="trait.trait_type == 'Speed' || trait.trait_type == 'Burst' || trait.trait_type == 'Count' " >
                  {{trait.trait_type}} {{trait.value}}
                </div>
              </div>
                            
            </div>
          </div>
          <div class="inventory">
            <div v-for="(ownedCollection, index) in ownedItems" 
                :key="index"
                class="itemGrid"
            >
                <div style="width:100%;">{{index}}s</div>
                
                <div 
                    v-for="(ownedItem, index2) in ownedCollection" 
                    :key="index2"
                >
                    
                    <!-- <span>{{ownedItem.metadata.name}}</span>
                    <span>{{ownedItem.equipped}}</span> -->
                    <div class="ownedItem" v-if="selectedSubstrateAddress != ownedItem.collectionAddress && !ownedItem.equipped">
                      <!-- {{ownedItem.metadata}} -->
                        <div v-if="ownedItem.equipped">
                            <!-- grayscale -->
                        </div>
                        <div 
                            v-else
                            @click="equipItem(ownedItem.tokenId, ownedItem.collectionAddress, selectedSubstrateId, selectedSubstrateAddress)"
                        >
                            <img :src="ownedItem.metadata.image" class="tinyThumb" />
                    
                            #{{ownedItem.tokenId}}
                        </div>
                    </div>
                    
                    
                </div>
                
            </div>

          </div>
        </div>
      </div>
    </div>
</template>
<script>
  import Button from '/src/components/ui/Button.vue';
  import Ethereum from '/src/components/common/Ethereum.vue';
  import EquipSkeleton from './components/EquipSkeleton.vue';
  
  import { computed, ref, onMounted, watch } from 'vue';
  import { useStore } from 'vuex';
  
  export default {
    components: {
        Button,
        Ethereum,
        EquipSkeleton
    },
  
    setup() {
      const store = useStore();
      const isLoading = ref(true);
      const isConnected = computed(() => {
        return store.state.ethers.canSign;
      });

      const loggedInAddress = computed(() => {
        return store.state.ethers.address;
      });

      const currentItem = ref({

          image: '',
          metadata: '',
          description: '',
          name: ''

      });

      const currentImage = ref('')

      const selectItem = async (index, index2) => {
        const ownedSubs = store.state.composite.ownedSubstrates;
        let ownedItem = ownedSubs[index][index2];
        console.log("item", index, index2, ownedItem);
        currentItem.value = ownedSubs[index][index2];
        currentImage.value = ownedSubs[index][index2].metadata.image

        selectedSubstrateId.value = ownedItem.tokenId;
        selectedSubstrateAddress.value = ownedItem.collectionAddress;

      };

      const basic = async () => {

      };

      const initialize = async () => {
        await store.dispatch(
          'composite/getOwnedSubstrates',
          { },
          { root: true }
        );

        store.dispatch(
          'composite/getOwnedItems',
          { },
          { root: true }
        );

        isLoading.value = false;

        console.log("ownedSubs", store.state.composite.ownedSubstrates);
        console.log("ownedItems", store.state.composite.ownedItems);
      };

      const ownedItems = computed(() => {
        let owned = {
          'Drone': [],
          'Engine': [],
          'Weapon': [],
          'Cosmetic': [],
        };
        for(let collection of Object.values(store.state.composite.ownedItems)){
          for(let item of collection){
            
            for(let attr of item.metadata.attributes){
              // console.log("type", attr.trait_type, attr.value);
              if(attr.trait_type == 'Type' && attr.value != 'Space Ship'){
                
                owned[attr.value].push(item);
              }
            }

          }
          // if(item.metadata.attributes){

          // }
          
        }
        console.log("sorted items", owned);
        return owned;
      });

      /*************************** */

      const substrateImage = computed(() => {
        return store.state.composite.substrateItem.image;
      });
  




      const ownedSubstrates = computed(() => {
        return store.state.composite.ownedSubstrates;
      });
  
      // const initialize = async () => {
      //   console.log("logged in", loggedInAddress.value)
      //   // await store.dispatch(
      //   //   'composite/getMintableItems',
      //   //   { },
      //   //   { root: true }
      //   // );

      //   await store.dispatch(
      //     'composite/getOwnedSubstrates',
      //     { },
      //     { root: true }
      //   );

      //   store.dispatch(
      //     'composite/getOwnedItems',
      //     { },
      //     { root: true }
      //   );
        
      //   // await store.dispatch(
      //   //   'composite/getEquippableItems',
      //   //   { },
      //   //   { root: true }
      //   // );

      //   console.log("ownedItems", store.state.composite.ownedItems);
      // };
      const selectedCollection = ref({});
  
      const manage = async (itemId, itemCollection) => {
        console.log("manage", ownedSubstrates.value);
        selectedSubstrateId.value = itemId;
        selectedSubstrateAddress.value = itemCollection;

        for(let collection of ownedSubstrates.value){
            for(let substrate of collection){
                console.log("for substrate", substrate)
                if(substrate.tokenId == itemId && substrate.collectionAddress.toLowerCase() == itemCollection.toLowerCase()){
                    selectedCollection.value = substrate
                }
            }
        }

        showEquipModal.value = true;
      };

      const equipItem = async (layerTokenId, layerTokenAddress, substrateTokenId, substrateTokenAddress) => {
        console.log("equipItem", layerTokenId, layerTokenAddress, substrateTokenId, substrateTokenAddress);
        await store.dispatch(
          'composite/equipItem',
          { 
            layerTokenId: layerTokenId,
            layerTokenAddress: layerTokenAddress,
            substrateTokenId: substrateTokenId,
            substrateTokenAddress: substrateTokenAddress
          },
          { root: true }
        );
      }

      const unEquipItem = async (layerTokenId, layerTokenAddress, substrateTokenId, substrateTokenAddress) => {
        await store.dispatch(
          'composite/unEquipItem',
          { 
            layerTokenId: layerTokenId,
            layerTokenAddress: layerTokenAddress,
            substrateTokenId: substrateTokenId,
            substrateTokenAddress: substrateTokenAddress
          },
          { root: true }
        );
      }

      const selectedSubstrateId = ref(0);
      const selectedSubstrateAddress = ref("0x0");
      const showEquipModal = ref(false);
      const toggleModal = async ( state ) => {
        showEquipModal.value = state;
      };

      return {
        currentItem,
        currentImage,
        ownedItems,
        isConnected,
        isLoading,

        store,
        equipItem,
        loggedInAddress,
        manage,
        
        ownedSubstrates,
        selectedCollection,
        selectedSubstrateId,
        selectedSubstrateAddress,
        showEquipModal,
        substrateImage,
        toggleModal,
        unEquipItem,
        initialize,

        selectItem
      };
    }
  };
  </script>
  <style scoped lang="scss">
    .primary {
      border: 1px solid green;
      width: 98%;
      margin: 0 1%;
    }
    .secondary {
      border: 1px solid yellow;
      display:inline-block;
      height:800px;
      overflow-y:auto;
      vertical-align: top;
    }
    .left{
      
      width:15%;
    }
    .right{
      
      width:85%;
    }
    .selectCell {
      display:inline-block;
      vertical-align: top;
      margin:10px;
    }
    .currentItem {
      border: 1px solid red;

    }
    .currentItemImage {
      border: 1px solid cyan;
      width: 28%;
      margin: 1%;
      display:inline-block;
      background:#000;
    }
    .currentItemEquip {
      vertical-align: top;
      border: 1px solid orange;
      width: 68%;
      margin: 1%;
      display:inline-block;
      overflow-y:auto;
      height:355px;
      padding:15px;
    }
    .inventory {
      border: 1px solid purple;
      margin: 1%;


    }

    .equippedRow {
      display: inline-block;
      vertical-align: top;
      padding: 10px;
      margin:10px;
      
    }
    .attributes {
      border:1px solid red;
      padding:5px;
    }
    .smThumb {
      width:100px;
      height:100px;
    }
    .thumb {
      width:200px;
      height:200px;
    }
    .lgThumb {
      width:348px;
      height:348px;
      
    }

    .gridwrap {
      display:flex;
      flex-direction:row;
      flex-wrap: wrap;
      height:400px;
      overflow-y:auto;
    }
    .itemGrid {
      background: rgb(0,0,0);
      width:100%;
      display:flex;
      flex-direction:row;
      flex-wrap:wrap;
    }
    .ownedItem {
      margin: 14px;
      width:120px;
      height:100px;
      background: rgb(5,5,5);
      border:1px solid rgb(0,0,0);
      cursor:pointer;
    }
    .ownedItem:hover{
      border:1px solid red;
    }
    .tinyThumb {
      width:80px;
      height: 80px;
    }



  // .home {
  //   text-align: center;
  //   padding: 50px;
  // }
  // .main {
  //   width:80%;
  //   margin:120px auto;
  // }

  // .saleItem {
  //   width:300px;
  //   margin:10px;
  //   padding:20px;
  // }
  // .saleGrid {
  //   display:flex;
  //   flex-direction:row;
  //   flex-wrap:wrap;
  // }

  // .mintModal {
  //   background:rgb(0,0,0);
  //   margin:50px auto;
  //   width: 1080px;

  //   z-index:111;
  //   border:2px solid red;
  //   padding:20px;
  // }
  // h2 {
  //   margin-bottom:30px;
  // }
  // .mintModalInfo {
  //   display:flex;
  //   flex-direction:row;
  // }
  // .mintBlackout {
  //   background:rgba(0,0,0,.8);
  //   position:fixed;
  //   top:0px;
  //   width:100%;
  //   height:100%;

  // }
  // .closeButton {
  //   float:right;
  //   cursor:pointer;
  // }

  // .equippedItem {
  //   width:640px;
  //   margin:15px 0;
  // }
  // .equippedCell {
  //   display:inline-block;
    
  //   vertical-align: top;
  //   margin:5px;
  // }
  // .equippedText {
  //   width:400px;
  // }
  .unequipButton {
    float:right;
  }
  </style>
  