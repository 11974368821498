
const types = [
	{
		name: 'speed',
		type: 'status',
		duration: 3000000,
		multiplier: 40
	},
	{
		name: 'rapid_fire',
		type: 'status',
		duration: 3000000,
		multiplier: 5
	},
	{
		name: 'invincibility',
		type: 'status',
		duration: 0,
		multiplier: 10000
	},
	{
		name: 'nuke',
		type: 'instant',
		duration: 0,
		multiplier: 1
	}
]

module.exports = class {
	constructor(x, y){
		this.x = x;
		this.y = y;
		this.width = 100;
		this.height = 50;
		this.speed = 5;
		this.direction = -1;
		this.received = false;
		this.index = Math.floor(Math.random() * (types.length));
		// this.index = 0;
		this.powerup = types[this.index];
		console.log("pow",this.index, this.powerup);
		this.name = this.powerup.name;
		this.type = this.powerup.type;
		this.multiplier = this.powerup.multiplier;
		this.duration = this.powerup.duration;
		this.markedForDeletion = false;

		this.audio = {
			powerup: new Audio()
		}
        this.audio.powerup.src = 'audio/powerup.mp3';
        this.audio.powerup.volume = 0.5;

		this.image = new Image();
		this.image.src = 'images/powerup.png';
		this.frame = 0;
		this.fps = 20;
		this.frameInterval = 400 / this.fps;
		this.durationCounter = 0;
	}
	draw(context) {
		context.shadowColor=`rgb(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)})`;
		context.shadowBlur=30;
		context.drawImage(this.image, this.frame * this.width, 0, this.width, this.height, this.x - this.width/2, this.y - this.height/2, this.width, this.height);
		context.shadowBlur=0;
	}
	update(player){
		// if(player.activePowerup){
		// 	if(this.durationCounter > this.duration){
		// 		this.durationCounter = 0;
		// 		player.resetSpeed();
		// 		player.resetWeapons();
		// 	}else{
		// 		this.durationCounter++;
		// 	}
		// 	console.log("duration", this.durationCounter);
		// }
		
		
		this.x += -1 * this.speed;
		if(player){
			const dx = player.x - this.x;
			const dy = player.y - this.y;
			const distance = Math.sqrt(dx*dx + dy*dy);

			if(distance < player.width/2 + this.width/2){
				console.log("POWERUP");
				this.received = true;
				this.markedForDeletion = true;
				this.audio.powerup.play();
				
			}
		}

		if(this.frameTimer > this.frameInterval){
            this.frame += 1;
            this.frameTimer = 0;
        }else{
            this.frameTimer += 10; 
        }
	}

}